import PageLoading from "../../components/PageLoading/PageLoading"
import TableHeader from "../../components/TableHeader/TableHeader"
import { IconButton, Link } from '@mui/material'
import InformationTable from "../../components/Table/InformationTable"
import css from "./Formaciones.module.css"
import { downloadCSVFile, getCSVContent } from "../../utils/csv"
import { textFilter } from "../../utils/table"
import { getFormaciones } from "../../utils/api/formaciones"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useNotification } from "../../context/NotificationManager/NotificationManager"

const Formaciones = () => {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: formacionList=[] } = useQuery({
    queryKey: ['formacion', 'formacion', 'list'], 
    queryFn: ()=> getFormaciones()
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las formaciones', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'E-mail'],
      data: formacionList,
      transform: c=> ([
        c.nombre,
        c.email,
      ])
    })
    downloadCSVFile(content, 'formaciones')
  }

  const filterSearch = alumno=> {
    return textFilter({ 
      object: alumno, 
      fields: ['nombre', 'email'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Formaciones activas'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Nombre', key: 'nombre' },
          { title: 'E-mail', key: 'email' }
        ]}
        data={formacionList.filter(filterSearch).map((formacion) => ({
          nombre: (
            <Link className={css.link} href={`/formaciones/${formacion.id}/ficha`}>
              {formacion.nombre}
            </Link>
          ),
          email: formacion.email || "--",
        }))}
      />

    </PageLoading>
  )
}

export default Formaciones
