import { useParams } from "react-router-dom";
import { getFormacion } from "../utils/api/formaciones";
import { useQuery } from "@tanstack/react-query";
import { useNotification } from "../context/NotificationManager/NotificationManager";

const useFormacion = () => {

  const notification = useNotification()
  const { id } = useParams();

  const { isLoading, data: formacion = {} } = useQuery({
    queryKey: ['formacion', 'formacion', id],
    enabled: !!id,
    queryFn: () => getFormacion(id)
      .then(datos => datos)
      .catch(err => {
        notification.error({ title: 'Error al recuperar los datos de la formación', content: err })
        return {}
      })
  })

  return {
    isLoading,
    id,
    nombre: formacion ? formacion.nombre : '',
    formacion
  }
}

export default useFormacion
