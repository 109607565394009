import FormacionHeader from '../../../components/FormacionHeader/FormacionHeader'
import Tabs from '../../../components/Tabs/Tabs'
import VerAsistencias from './tabs/VerAsistencias/VerAsistencias'
import GestionarAsistencias from './tabs/GestionarAsistencias/GestionarAsistencias'

const Asistencias = () => (
  <>
    <FormacionHeader label='Asistencias' />
    <Tabs
      tabs={[
        { 
          name: 'Ver asistencias', 
          content: <VerAsistencias />
        },
        { 
          name: 'Gestionar asistencias', 
          content: <GestionarAsistencias /> 
        },
      ]}
    />
  </>
)

export default Asistencias
