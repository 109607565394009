import { useState } from 'react'
import { IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import Calendar from '../../../../../components/Calendar/Calendar'
import { getAuth } from '../../../../../utils/auth'
import { dateFromString, dateToMonth, dateToString, getStartOfMonth, isSameMonth, monthToString } from '../../../../../utils/date'
import { getPlanificacionClases } from '../../../../../utils/documents'
import { mergeClases } from '../../../../../utils/model/asistencias'
import useFormacion from '../../../../../hooks/useFormacion'
import css from './VerAsistencias.module.css'

const AsistenciaCalendario = ({ asignatura, nivel, hora, centro, dimmed })=> {
  const userData = getAuth()
  return (
    <div className={css.calendarEvent} data-status={dimmed ? 'dimmed' : 'normal'}>
      <p className={css.calendarEventRow}>
        <p className={css.calendarEventAsignatura}>
          {asignatura} <span className={css.calendarEventDetail}>({nivel.substring(0, 4)})</span>
        </p>
      </p>
      <p className={css.calendarEventRow}>
        {hora} {userData.centro === centro ? '' : <span className={css.calendarEventDetail}>({centro})</span>}
      </p>
    </div>
  )
}

const VerAsistencias = () => {

  const userData = getAuth()

  const { formacion, nombre } = useFormacion()

  const [mes, setMes] = useState(getStartOfMonth())
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [isGeneratingDocument, setGeneratingDocument] = useState(false)

  const asistencias = (formacion.asistencias || []).map(asistencia=> ({
    ...asistencia,
    fecha: dateToString(asistencia.fecha)
  }))

  const handleMonthChange = (offset) => {
    const newMes = new Date(mes)
    newMes.setMonth(newMes.getMonth() + offset)
    setMes(newMes)
  }

  const generatePlanification = ()=> {
    if (isGeneratingDocument) return
    setGeneratingDocument(true)
    getPlanificacionClases({ 
      academia: userData.centro, 
      mes: monthToString(mes), 
      alumno: nombre, 
      asistencias: mergeClases(asistencias)
        .map(asistencia=> ({
          centro: asistencia.centro,
          fecha: asistencia.fecha,
          hora: asistencia.hora.substring(0, 5),
          asignatura: asistencia.asignatura, 
          detalle: `${asistencia.count} ${asistencia.count === 1 ? 'hora' : 'horas'}`
        }))
        .filter(asistencia=> isSameMonth(dateFromString(asistencia.fecha), mes))
        .sort((a, b)=> {
          const momentA = `${a.fecha} ${a.hora}`
          const momentB = `${b.fecha} ${b.hora}`
          if (momentA < momentB) return -1
          if (momentA > momentB) return 1
          return 0
        })
    })
    .finally(()=> setGeneratingDocument(false))
  }

  const asistenciasCalendario = asistencias
    .sort((a, b)=> {
      if (dateFromString(a.fecha) < dateFromString(b.fecha)) return -1
      if (dateFromString(a.fecha) > dateFromString(b.fecha)) return 1
      if (a.hora < b.hora) return -1
      if (a.hora > b.hora) return 1
      return 0
    })
    .map((asistencia, index)=> ({
      fecha: asistencia.fecha,
      component: (
        <AsistenciaCalendario
          key={index}
          asignatura={asistencia.asignatura}
          nivel={asistencia.nivel}
          hora={asistencia.hora}
          centro={asistencia.centro}
          dimmed={!isSameMonth(dateFromString(asistencia.fecha), mes)}
        />
      )
    }))
    .reduce((acc, asistencia)=> {
      return {
        ...acc,
        [asistencia.fecha]: [
          ...(acc[asistencia.fecha] || []),
          asistencia.component
        ]
      }
    }, {})

  return (
    <div className={css.calendar}>
      <div className={css.monthSelection}>
        <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(-1)}>
          <i className='material-icons'>arrow_back</i>
        </IconButton>
        <p className={css.calendarMonth}>
          {dateToMonth(mes)}
        </p>
        <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(+1)}>
          <i className='material-icons'>arrow_forward</i>
        </IconButton>
      </div>
      <Calendar
        month={monthToString(mes)}
        events={asistenciasCalendario}
      />
      <SpeedDial
        className={css.speed}
        ariaLabel="Nuevo documento"
        icon={<SpeedDialIcon />}
        onClose={()=> setSpeedDialOpen(false)}
        onOpen={()=> setSpeedDialOpen(true)}
        open={speedDialOpen && !isGeneratingDocument}
      >
        <SpeedDialAction
          icon={<i className='material-icons'>history_edu</i>}
          tooltipTitle='Planificación'
          tooltipOpen
          onClick={generatePlanification}
        />
      </SpeedDial>
    </div>
  )
}

export default VerAsistencias