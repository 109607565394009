import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../../../../context/NotificationManager/NotificationManager"
import { dateToString } from "../../../../../utils/date"
import { Button, Checkbox, Chip, IconButton, Typography } from "@mui/material"
import PageLoading from "../../../../../components/PageLoading/PageLoading"
import { pluralize } from "../../../../../utils/string"
import InformationTable from "../../../../../components/Table/InformationTable"
import { dateSort } from "../../../../../utils/table"
import ModalEditarAsistencia from "../../../../../modals/ModalEditarAsistencia/ModalEditarAsistencia"
import ModalEditarProfesorAsistencias from "../../../../../modals/ModalEditarProfesorAsistencias/ModalEditarProfesorAsistencias"
import ModalEditarCentroAsistencias from "../../../../../modals/ModalEditarCentroAsistencias/ModalEditarCentroAsistencias"
import ModalEditarAsignaturaAsistencias from "../../../../../modals/ModalEditarAsignaturaAsistencias/ModalEditarAsignaturaAsistencias"
import ModalPlanificarClasesFormacion from "../../../../../modals/ModalPlanificarClasesFormacion/ModalPlanificarClasesFormacion"
import useFormacion from "../../../../../hooks/useFormacion"
import { addAsistencias, updateAsistencias } from "../../../../../utils/api/formaciones"
import css from './GestionarAsistencias.module.css'

const SinProfesor = ()=> (
  <span className={css.placeholder}>Sin profesor</span>
)

const GestionarAsistencias = () => {

  const notification = useNotification()
  const queryClient = useQueryClient()
  const { id, formacion, isLoading } = useFormacion();

  const [asistenciaEdit, setAsistenciaEdit] = useState(null)
  const [asistenciasSeleccionadas, setAsistenciasSeleccionadas] = useState([])

  const [openPlanificar, setOpenPlanificar] = useState(false)

  const [bulkEditProfesor, setBulkEditProfesor] = useState(false)
  const [bulkEditCentro, setBulkEditCentro] = useState(false)
  const [bulkEditAsignatura, setBulkEditAsignatura] = useState(false)

  const asistencias = (formacion.asistencias || [])
    .sort((a, b)=> {
      if (a.fecha < b.fecha) return -1
      if (a.fecha > b.fecha) return 1
      if (a.hora < b.hora) return -1
      if (a.hora > b.hora) return 1
      return 0
    })
    .map((asistencia, index)=> ({ 
      ...asistencia,
      id: index+1,
      fecha: dateToString(asistencia.fecha)
    }))

  const { isPending: isAsistenciasAdding, mutate: crearAsistencias } = useMutation({
    mutationFn: addAsistencias,
    onSuccess: () => {
      notification.success({ title: 'Asistencias añadidas', content: 'Se han añadido correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['formacion', 'formacion', id]
      })
      setOpenPlanificar(false)
    },
    onError: err => {
      notification.error({ title: 'Error añadiendo', content: err })
    },
  })

  const { isPending: isAsistenciasUpdating, mutate: actualizarAsistencias } = useMutation({
    mutationFn: updateAsistencias,
    onSuccess: () => {
      notification.success({ title: 'Asistencias modificadas', content: 'Se han modificado correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['formacion', 'formacion', id]
      })
      setAsistenciaEdit(null)
      setAsistenciasSeleccionadas([])
      setBulkEditCentro(false)
      setBulkEditProfesor(false)
      setBulkEditAsignatura(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando las asistencias', content: err })
    },
  })

  const asignaturas = asistencias.reduce((acc, asistencia)=> {
    const asignatura = `${asistencia.asignatura} (${asistencia.nivel})`
    if (!acc.includes(asignatura)) acc.push(asignatura)
    return acc
  }, [])

  const handleAsistenciaSeleccionada = (asistenciaId) => {
    if (asistenciasSeleccionadas.includes(asistenciaId)) {
      setAsistenciasSeleccionadas(asistenciasSeleccionadas.filter(id=> id !== asistenciaId))
    } else {
      setAsistenciasSeleccionadas([...asistenciasSeleccionadas, asistenciaId])
    }
  }

  const handleAllAsistenciasSeleccionadas = ()=> {
    if (asistenciasSeleccionadas.length === asistencias.length) {
      setAsistenciasSeleccionadas([])
    } else {
      setAsistenciasSeleccionadas(asistencias.map(asistencia=> asistencia.id))
    }
  }

  const handlePlanificarClases = ({ asignatura, profesor, centro, observaciones, horas })=> {
    if (isAsistenciasAdding) return
    const nuevasAsistencias = horas.map(hora=> ({
      asignatura,
      centro,
      fecha: hora.fecha,
      hora: hora.hora,
      observaciones,
      profesor,
    }))
    crearAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleUpdateAsistencia = ({ id, asignatura, profesor, centro, observaciones })=> {
    const nuevasAsistencias = asistencias
      .map(asistencia=> ({
        asignatura: asistencia.id === id ? asignatura : asistencia.asignaturaId,
        centro: asistencia.id === id ? centro : asistencia.centro,
        fecha: asistencia.fecha,
        hora: asistencia.hora,
        observaciones: asistencia.id === id ? observaciones : asistencia.observaciones,
        profesor: asistencia.id === id ? profesor : asistencia.profesorId,
      }))
    actualizarAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleUpdateAcademia = ({ centro })=> {
    if (isAsistenciasUpdating) return
    const nuevasAsistencias = asistencias.map(asistencia=> ({
      asignatura: asistencia.asignaturaId,
      centro: asistenciasSeleccionadas.includes(asistencia.id) ? centro : asistencia.centro,
      fecha: asistencia.fecha,
      hora: asistencia.hora,
      observaciones: asistencia.observaciones,
      profesor: asistencia.profesorId,
    }))
    actualizarAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleUpdateProfesor = ({ profesor })=> {
    if (isAsistenciasUpdating) return
    const nuevasAsistencias = asistencias.map(asistencia=> ({
      asignatura: asistencia.asignaturaId,
      centro: asistencia.centro,
      fecha: asistencia.fecha,
      hora: asistencia.hora,
      observaciones: asistencia.observaciones,
      profesor: asistenciasSeleccionadas.includes(asistencia.id) ? profesor : asistencia.profesorId,
    }))
    actualizarAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleUpdateAsignatura = ({ asignatura })=> {
    if (isAsistenciasUpdating) return
    const nuevasAsistencias = asistencias.map(asistencia=> ({
      asignatura:  asistenciasSeleccionadas.includes(asistencia.id) ? asignatura : asistencia.asignaturaId,
      centro: asistencia.centro,
      fecha: asistencia.fecha,
      hora: asistencia.hora,
      observaciones: asistencia.observaciones,
      profesor: asistencia.profesorId,
    }))
    actualizarAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleBorrarAsistencias = ()=> {
    const nuevasAsistencias = asistencias
      .filter(asistencia=> !asistenciasSeleccionadas.includes(asistencia.id))
      .map(asistencia=> ({
        asignatura: asistencia.asignaturaId,
        centro: asistencia.centro,
        fecha: asistencia.fecha,
        hora: asistencia.hora,
        observaciones: asistencia.observaciones,
        profesor: asistencia.profesorId,
      }))
    actualizarAsistencias({ asistencias: nuevasAsistencias, id })
  }

  const handleModificarAcademia = ()=> setBulkEditCentro(true)
  const handleModificarProfesor = ()=> setBulkEditProfesor(true)
  const handleModificarAsignatura = ()=> setBulkEditAsignatura(true)

  return (
    <>
      <div className={css.main}>
        {asistenciasSeleccionadas.length > 0 && (
          <div className={css.bulk}>
            <Chip
              className={css.bulkAction}
              icon={<i className='material-icons'>account_balance</i>} 
              label={`Modificar academia de ${asistenciasSeleccionadas.length} ${pluralize('asistencia', asistenciasSeleccionadas.length)}`} 
              color={isAsistenciasUpdating ? 'default' : 'primary'}
              variant="outlined"
              onClick={handleModificarAcademia}
            />
            <Chip
              className={css.bulkAction}
              icon={<i className='material-icons'>school</i>} 
              label={`Modificar profesor de ${asistenciasSeleccionadas.length} ${pluralize('asistencia', asistenciasSeleccionadas.length)}`} 
              color={isAsistenciasUpdating ? 'default' : 'secondary'}
              variant="outlined"
              onClick={handleModificarProfesor}
            />
            <Chip
              className={css.bulkAction}
              icon={<i className='material-icons'>architecture</i>} 
              label={`Modificar asignatura de ${asistenciasSeleccionadas.length} ${pluralize('asistencia', asistenciasSeleccionadas.length)}`} 
              color={isAsistenciasUpdating ? 'default' : 'tertiary'}
              variant="outlined"
              onClick={handleModificarAsignatura}
            />
            <Chip
              className={css.bulkAction}
              icon={<i className='material-icons'>delete</i>} 
              label={`Borrar ${asistenciasSeleccionadas.length} ${pluralize('asistencia', asistenciasSeleccionadas.length)}`} 
              color={isAsistenciasUpdating ? 'default' : 'error'}
              variant="outlined"
              onClick={handleBorrarAsistencias}
            />
          </div>
        )}
        <div className={css.title}>
          <Typography variant='body 2'>
            {asistencias.length} {pluralize('asistencia', asistencias.length)} de {asignaturas.length} {pluralize('asignatura', asignaturas.length)}
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            startIcon={<i className='material-icons'>add</i>}
            onClick={()=> setOpenPlanificar(true)}
          >
            Añadir clases
          </Button>
        </div>
        <div className={css.content}>
          <PageLoading isLoading={isLoading}>
            {asistencias.length > 0 && (
              <InformationTable
                size='small'
                details={[
                  { 
                    title: (
                      <Checkbox  
                        checked={asistenciasSeleccionadas.length === asistencias.length}
                        onChange={handleAllAsistenciasSeleccionadas}
                      />
                    ), 
                    key: 'checkbox', 
                    sortDisabled: true 
                  },
                  { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
                  { title: 'Hora', key: 'hora' },
                  { title: 'Centro', key: 'centro' },
                  { title: 'Asignatura', key: 'asignatura'},
                  { title: 'Profesor', key: 'profesor' },
                  { title: 'Clase', key: 'tipo' },
                  { title: 'Observaciones', key: 'observaciones', sortDisabled: true, cell: css.observaciones },
                  { title: 'Opciones', key: 'opciones', align: 'center', sortDisabled: true },
                ]}
                data={asistencias.map(asistencia => ({
                  checkbox: (
                    <Checkbox 
                      checked={asistenciasSeleccionadas.includes(asistencia.id)}
                      onChange={()=> handleAsistenciaSeleccionada(asistencia.id)}
                    />
                  ),
                  tipo: asistencia.clase,
                  centro: asistencia.centro,
                  profesor: asistencia.profesor || <SinProfesor />,
                  asignatura: asistencia.asignatura,
                  fecha: asistencia.fecha,
                  hora: asistencia.hora,
                  observaciones: asistencia.observaciones,
                  opciones: (
                    <IconButton 
                      size='small' 
                      color='secondary' 
                      onClick={()=> setAsistenciaEdit(asistencia)}
                    >
                      <i className='material-icons'>edit</i>
                    </IconButton>
                  )
                }))}
              />
            )}
          </PageLoading>
        </div>
      </div>
      <ModalPlanificarClasesFormacion
        open={openPlanificar}
        formacion={formacion}
        disabled={isAsistenciasAdding}
        onClose={()=> setOpenPlanificar(false)}
        onSubmit={handlePlanificarClases}
      />
      <ModalEditarAsistencia
        open={!!asistenciaEdit}
        asistencia={asistenciaEdit}
        onClose={()=> setAsistenciaEdit(null)}
        onSubmit={handleUpdateAsistencia}
      />
      <ModalEditarProfesorAsistencias
        open={bulkEditProfesor}
        onClose={()=> setBulkEditProfesor(false)}
        onSubmit={handleUpdateProfesor}
      />
      <ModalEditarCentroAsistencias
        open={bulkEditCentro}
        onClose={()=> setBulkEditCentro(false)}
        onSubmit={handleUpdateAcademia}
      />
      <ModalEditarAsignaturaAsistencias
        open={bulkEditAsignatura}
        onClose={()=> setBulkEditAsignatura(false)}
        onSubmit={handleUpdateAsignatura}
      />
    </>
  )
}

export default GestionarAsistencias