import { useState } from "react"
import logo from "./logo-lateral.png"
import css from "./SideMenu.module.css"
import { NavLink } from "react-router-dom"
import routes from "./routes.json"

const MenuElementCollapse = ({ hasChildren, isActive }) => {
  if (!hasChildren) return null
  return (
    <i className={`material-icons ${css.collapseIcon}`}>
      {isActive ? "keyboard_arrow_down" : "keyboard_arrow_up"}
    </i>
  )
}

const MenuElement = ({
  icon,
  text,
  name,
  to,
  activeRoute,
  parent,
  onClick,
  children,
}) => {

  const isActive = activeRoute && activeRoute.startsWith(name)
  const hasChildren = !!children

  const handleClick = (event) => {
    if (hasChildren) {
      event.preventDefault()
      onClick(isActive ? parent : name)
    }
    else {
      onClick(name)
    }
  }

  return (
    <li className={css.element}>
      <NavLink
        to={to}
        className={css.elementLink}
        data-status={isActive ? "active" : "inactive"}
        onClick={handleClick}
      >
        <p className={css.elementContent}>
          <i className={`material-icons ${css.elementIcon}`}>{icon}</i>
          <span className={css.elementText}>{text}</span>
        </p>
        <MenuElementCollapse hasChildren={hasChildren} isActive={isActive} />
      </NavLink>
      {hasChildren && (
        <ul
          className={css.elementList}
          data-status={isActive ? "active" : "inactive"}
        >
          {children}
        </ul>
      )}
    </li>
  )
}

const SideMenu = () => {
  const [activeRoute, setActiveRoute] = useState("")

  const handleRouteClick = (route) => {
    if (activeRoute === route) setActiveRoute("")
    else setActiveRoute(route)
  }

  return (
    <aside className={css.menu}>
      <nav className={css.navigation}>
        {routes.filter(r=> !r.hidden).map((route, index) => {
          if (route.children) {
            return (
              <MenuElement
                key={index}
                name={route.name}
                icon={route.icon}
                text={route.title}
                to={route.to}
                activeRoute={activeRoute}
                onClick={handleRouteClick}
              >
                {route.children.map((child, child_index) => {
                  if (child.children) {
                    return (
                      <MenuElement
                        key={`${index}.${child_index}`}
                        name={child.name}
                        icon={child.icon}
                        text={child.title}
                        to={child.to}
                        activeRoute={activeRoute}
                        onClick={handleRouteClick}
                        parent={route.name}
                      >
                        {child.children.map((grandchild, grandchild_index) => {
                          return (
                            <MenuElement
                              key={`${index}.${child_index}.${grandchild_index}`}
                              name={grandchild.name}
                              icon={grandchild.icon}
                              text={grandchild.title}
                              to={grandchild.to}
                              activeRoute={activeRoute}
                              onClick={handleRouteClick}
                            />
                          )
                        })}
                      </MenuElement>
                    )
                  } else {
                    return (
                      <MenuElement
                        key={`${index}.${child_index}`}
                        name={child.name}
                        icon={child.icon}
                        text={child.title}
                        to={child.to}
                        activeRoute={activeRoute}
                        onClick={setActiveRoute}
                      />
                    )
                  }
                })}
              </MenuElement>
            )
          } else {
            return (
              <MenuElement
                key={index}
                name={route.name}
                icon={route.icon}
                text={route.title}
                to={route.to}
                activeRoute={activeRoute}
                onClick={setActiveRoute}
              />
            )
          }
        })}
      </nav>
      <img className={css.bottom} src={logo} alt="mundoestudiante" />
    </aside>
  )
}

export default SideMenu
