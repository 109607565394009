import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import { getAuth } from "../../utils/auth"
import { useEffect, useState } from "react"
import { dateFromMonthString, dateFromString, dateToString, dateToWeekday, forwardDays, forwardMonths, isSameMonth } from "../../utils/date"
import { getHorarios } from "../../utils/api/modelosHorario"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import { isAnyBlank, isBlank, pluralize } from "../../utils/string"
import ProfesorSelector from "../../components/form/ProfesorSelector/ProfesorSelector"
import MesRecienteSelector from "../../components/form/MesRecienteSelector/MesRecienteSelector"
import css from './ModalPlanificarClasesFormacion.module.css'

const HoraCheckbox = ({ fecha, hora, horas, asistencias, onChange })=> (
  <FormControlLabel 
    control={(
      <Checkbox 
        checked={(
          horas.some(h=> h.fecha === fecha && h.hora === hora) || 
          asistencias.some(a=> a.fecha === fecha && a.hora === hora)
        )} 
        onChange={(e)=> onChange(e, { fecha, hora, checked: e.target.checked })} 
      />
    )} 
    label={hora}
    disabled={asistencias.some(a=> a.fecha === fecha && a.hora === hora)}
  />
)


const ConfigurarClase = ({ datos, onChange })=> (
  <>
    <AsignaturaSelector
      className={css.input}
      name='asignatura'
      label='Asignatura'
      value={datos.asignatura}
      onChange={(_e, value) => onChange('asignatura', value?.id || '')}
    />
    <AcademiaSelector
      className={css.input}
      name='academia'
      label='Academia'
      value={datos.centro}
      onChange={e => onChange('centro', e.target.value)}
    />
    <ProfesorSelector
      className={css.input}
      name='profesor'
      label='Profesor'
      value={datos.profesor}
      onChange={(_e, value)=> onChange('profesor', value?.id || '')}
    />
    <TextField
      className={css.input}
      label="Observaciones"
      multiline
      value={datos.observaciones}
      onChange={e=> onChange('observaciones', e.target.value)}
      variant='standard'
      fullWidth
    />
  </>
)

const ElegirMes = ({ mes, onChange })=> (
  <div className={css.mes}>
    <Alert className={css.info} severity='info'>
      Solo se pueden programar asistencias para un mes concreto. Elige a continuación qué mes quieres planificar.
    </Alert>
    <MesRecienteSelector
      className={css.input}
      name='mes'
      label='Mes a planificar'
      value={mes}
      backwardOptions={3}
      forwardOptions={3}
      onChange={e=> onChange(e.target.value)}
    />
  </div>
)

const ConfigurarHorario = ({ horas, horarios, asistencias, onChange })=> (
  <div className={css.horario}>
    {horarios.map(horario=> (
      <div key={horario.id} className={css.dia}>
        <Typography variant='body1' className={css.diaTitle}>
          {horario.fecha} ({horario.diaSemana})
        </Typography>
        <div className={css.horas}>
          <div className={css.horasBloque}>
            {horario.horasTemprano.map(hora=> (
              <HoraCheckbox
                key={hora}
                fecha={horario.fecha}
                hora={hora}
                horas={horas}
                asistencias={asistencias}
                onChange={onChange}
              />
            ))}
          </div>
          <div className={css.horasBloque}>
            {horario.horasTarde.map(hora=> (
              <HoraCheckbox
                key={hora}
                fecha={horario.fecha}
                hora={hora}
                horas={horas}
                asistencias={asistencias}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
)

const ModalPlanificarClasesFormacion = ({
  open,
  formacion,
  disabled,
  onClose,
  onSubmit,
}) => {

  const notification = useNotification()
  const userData = getAuth()

  const [step, setStep] = useState(0)

  const [datos, setDatos] = useState({
    asignatura: '',
    centro: userData.centro,
    profesor: '',
    observaciones: '',
  })

  const [mes, setMes] = useState('')
  const [horas, setHoras] = useState([])

  useEffect(()=> {
    if (open) {
      setStep(0)
      setDatos({
        asignatura: '',
        centro: userData.centro,
        profesor: '',
        observaciones: '',
      })
      setMes('')
      setHoras([])
    }
  }, [open, userData.centro])

  useEffect(()=> {
    setHoras([])
  }, [mes])

  const handleChangeDatos = (name, value) => {
    setDatos({ ...datos, [name]: value })
  }

  const mesPack = dateFromMonthString(mes)
  const mesSiguiente = forwardMonths(mesPack, 1)

  const asistenciaList = (formacion.asistencias || []).map(asistencia=> ({
    ...asistencia,
    fecha: dateToString(asistencia.fecha)
  }))

  const { data: horariosList=[] } = useQuery({
    queryKey: ['planificacion', 'modelo-horario', 'list', 'dates', datos.centro, mes],
    enabled: open && !!mes,
    queryFn: ()=> getHorarios({
      centro: datos.centro,
      fecha_inicio: dateToString(mesPack),
      fecha_fin: dateToString(mesSiguiente),
    })
      .then(datos=> datos
          .sort((a, b)=> a.fecha < b.fecha ? -1 : 1)
          .map(horario=> ({
          ...horario,
          horas: horario.horas.sort(),
          horasTemprano: horario.horas.filter(h=> h < '15:00').sort(),
          horasTarde: horario.horas.filter(h=> h >= '15:00').sort(),
          fecha: dateToString(horario.fecha),
          diaSemana: dateToWeekday(horario.fecha)
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los horarios', content: err })
        return []
      })
  })

  const handleChangeHora = (event, { fecha, hora, checked })=> {
    const isShift = event.nativeEvent.shiftKey
    // Si se pulsa normal, se marca la hora
    if (checked && !isShift) {
      setHoras([...horas, { fecha, hora }])
    } 
    // Si se pulsa con shift, se marcan todas las horas de ese día de la semana de a futuro
    else if (checked && isShift) {
      const newDays = [{ fecha, hora }]
      // Vamos sumando 7 dias
      const referenceDate = dateFromString(fecha)
      let currentDate = dateFromString(fecha)
      while (true) {
        currentDate = forwardDays(currentDate, 7)
        if (!isSameMonth(referenceDate, currentDate)) break
        const newFecha = dateToString(currentDate)
        // Comprobamos que está en el horario la hora
        if (!horariosList.some(h=> h.fecha === newFecha && h.horas.includes(hora))) continue
        // Comprobamos que no tiene ya una asistencia programada esa hora
        if (asistenciaList.some(a=> a.fecha === newFecha && a.hora === hora)) continue
        // Comprobamos que no tiene ya un check esa hora
        if (horas.some(h=> h.fecha === newFecha && h.hora === hora)) continue
        // Todo va bien - vamos añadiendo
        newDays.push({ fecha: newFecha, hora })
      }
      setHoras([...horas, ...newDays])
    } 
    // Si se pulsa normal, se desmarca la hora
    else if (!checked && !isShift) {
      setHoras(horas.filter(h=> !(h.fecha === fecha && h.hora === hora)))
    } 
    // Si se pulsa con shift, se desmarcan todas las horas de ese día de la semana de a futuro
    else {
      let newHoras = horas.filter(h=> !(h.fecha === fecha && h.hora === hora))
      // Vamos sumando 7 dias y quitando las horas
      const referenceDate = dateFromString(fecha)
      let currentDate = dateFromString(fecha)
      while (true) {
        currentDate = forwardDays(currentDate, 7)
        if (!isSameMonth(referenceDate, currentDate)) break
        const newFecha = dateToString(currentDate)
        newHoras = newHoras.filter(h=> !(h.fecha === newFecha && h.hora === hora))
      }
      // Quitamos todas
      setHoras(newHoras)
    }
  }

  const isContinueToStep1Disabled = isAnyBlank(datos.asignatura, datos.centro)
  const isContinueToStep2Disabled = isBlank(mes)

  const isSubmitDisabled = horas.length === 0

  const handleSubmit = ()=> {
    onSubmit({ ...datos, horas })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Planificar clases</DialogTitle>
      <Stepper className={css.stepper} activeStep={step} alternativeLabel>
        <Step>
          <StepLabel>Configurar asistencia</StepLabel>
        </Step>
        <Step>
          <StepLabel>Especificar mes</StepLabel>
        </Step>
        <Step>
          <StepLabel>Elegir horario</StepLabel>
        </Step>
      </Stepper>
      <DialogContent className={css.content}>
        {step === 0 && (
          <ConfigurarClase
            datos={datos}
            onChange={handleChangeDatos}
          /> 
        )}
        {step === 1 && (
          <ElegirMes mes={mes} onChange={setMes} /> 
        )}
        {step === 2 && (
          <ConfigurarHorario
            horas={horas}
            horarios={horariosList}
            asistencias={asistenciaList}
            onChange={handleChangeHora}
          />
        )}
      </DialogContent>
      <DialogActions>
        {step === 0 && (
          <>
            <Button color='error' onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color='primary'
              disabled={isContinueToStep1Disabled}
              onClick={() => setStep(1)}
            >
              Continuar
            </Button>
          </>
        )}
        {step === 1 && (
          <>
            <Button color='error' onClick={() => setStep(0)}>
              Atrás
            </Button>
            <Button
              color='primary'
              disabled={isContinueToStep2Disabled}
              onClick={() => setStep(2)}
            >
              Continuar
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Button color='error' onClick={() => setStep(1)}>
              Atrás
            </Button>
            <Button
              color='primary'
              disabled={isSubmitDisabled || disabled}
              onClick={handleSubmit}
            >
              Planificar
              {horas.length > 0 && ` ${horas.length} ${pluralize('clase', horas.length)}`}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )

}

export default ModalPlanificarClasesFormacion