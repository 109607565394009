import { GET, POST, PUT } from '../request'

export const getFormaciones = () => GET('/api/agora/formacion/formacion/list', {})

export const getFormacion = id => GET('/api/agora/formacion/formacion', { id })

export const getFormacionesYear = year => GET('/api/agora/formacion/formacion/year', { year })

export const addAsistencias = ({ id, asistencias }) => POST('/api/agora/formacion/formacion/asistencias', {}, { id, asistencias })

export const updateAsistencias = ({ id, asistencias }) => PUT('/api/agora/formacion/formacion/asistencias', {}, { id, asistencias })